




































































import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import useAttachment from "@/use/attachment";
import useApi from "@/use/api";

export default defineComponent({
  components: {
    pdf: () => import("vue-pdf"),
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const { downloadAttachment } = useAttachment({ root });
    const { axiosInstance } = useApi({ root });

    const loading = ref(false);
    const loaded = ref({});

    const policy = ref<{ id: string; company: any; date: string; file: any }[]>(
      []
    );
    const currentPolicy = ref<{
      id: string;
      company: any;
      date: string;
      file: any;
    } | null>(null);

    watch(
      policy,
      (policy) =>
        (currentPolicy.value = policy?.length >= 1 ? policy[0] : null),
      { deep: true, immediate: true }
    );

    const file = ref<{ id: string; name: string } | null>(null);

    watch(currentPolicy, (policy) => (file.value = policy?.file ?? null), {
      deep: true,
      immediate: true,
    });

    const fileUrl = ref<string | null>(null);

    const fetchFile = () => {
      if (file.value) {
        axiosInstance
          .get(`attachment/${file.value.id}/download`, { responseType: "blob" })
          .then(({ data }) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const uri = reader.result as string;
              if (uri) {
                fileUrl.value = uri;
              } else {
                fileUrl.value = null;
              }
            };
            reader.readAsDataURL(data);
          })
          .catch(() => {
            fileUrl.value = null;
          });
      } else {
        fileUrl.value = null;
      }
    };

    watch(file, fetchFile, { deep: true, immediate: true });

    const reset = () => {
      loading.value = false;
      loaded.value = false;
      policy.value = [];
    };

    const fetch = async () => {
      if (props.id?.length) {
        loading.value = true;

        for (const id of props.id) {
          if (id) {
            await axiosInstance
              .get(`fleet-policy/${id}`)
              .then(({ data: { fleetPolicy } }) => {
                if (!policy.value.map(({ id }) => id).includes(id)) {
                  policy.value.push(fleetPolicy);
                  loaded.value = { ...loaded.value, [id]: true };
                }
              })
              .catch(() => {
                policy.value = [];
                loaded.value = { ...loaded.value, [id]: false };
              });
          }
        }

        loading.value = false;
      } else reset();
    };

    onMounted(fetch);
    watch(() => props.id, fetch);

    const download = () =>
      file.value ? downloadAttachment(file.value.id) : void 0;

    const pdfData = reactive({
      page: 1,
      pageCount: 1,
      onPassword: (
        updatePassword: Function,
        reason: "NEED_PASSWORD" | "INCORRECT_PASSWORD"
      ) => {
        const msg =
          reason === "INCORRECT_PASSWORD"
            ? "Podaj poprawne hasło do pliku"
            : "Podaj hasło do pliku";
        const password = prompt(msg);
        return updatePassword(password);
      },
    });

    return {
      loading,
      loaded,
      policy,
      currentPolicy,
      file,
      fileUrl,
      download,
      pdfData,
    };
  },
});
